.sub-component-featured-products {
	.button {
		text-align: center;
		margin-top: 40px;
		margin-bottom: 0;

		.btn {
			min-width: 325px;
			padding: 9px 15px;
			height: 42px;
		}
	}
}
