.sub-component-checkmark-list {
	ul.checkmark-list {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			margin-bottom: 16px;
			padding-left: 38px;
			position: relative;

			&:before {
				font-family: "Font Awesome 5 Pro";
				font-weight: 300;
				content: "\f00c";
				position: absolute;
				top: -4px;
				left: 0;
				color: $red;
				font-size: 22px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
