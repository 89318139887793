body.woocommerce-checkout {
    .component_footer_map {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.component_footer_map {
	position: relative;
	z-index: 10;
	margin-left: 0;
	margin-right: 0;
	overflow: hidden;

	@include media-breakpoint-down(sm) {
		display: none;
	}

	
	
	

		.content_col {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			padding: 104px 95px;

			@media (max-width: 1600px) {
				padding: 60px 50px;
			}

			@include media-breakpoint-down(sm) {
				padding: 20px 15px;
			}

			&:before {
				content: "";
				background: #353535;
				opacity: 0.8;
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				z-index: 1;
			}

			.editor_content {
				position: relative;
				z-index: 5;
				color: #fff;

				h1, h2, h3 {
					color: #fff;
				}
			}

			.gform_body {

				.gform_fields {
					display: flex;
					flex-wrap: wrap;
					margin-left: -15px;
					margin-right: -15px;

					@media (max-width: 1600px) {
						display: block;
						margin-left: 0;
						margin-right: 0;
					}

					li.gfield {
						flex: 0 0 50%;
						max-width: 50%;
						padding: 0 15px;

						.gfield_label {
							left: 30px;
						}

						@media (max-width: 1600px) {
							max-width: 100%;
							padding: 0;
							margin-bottom: 15px;
						}

						&.datepicker {
							&:after {
								right: 15px;
							}
						}
					}
				}
			}

			.gform_footer {
				margin-top: 10px;

				button {
					width: 100%;
				}
			}
		}

		img {
			position: absolute;
			margin: auto;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		};

		.map_col {
			@include media-breakpoint-down(md) {
				height: 400px;
			}
		}	
}