.btn {
	display: inline-block;
	border: none;
	padding: 12px 15px;
	font-size: 16px;
	//transition: background 0.2s, color 0.2s;
	text-align: center;
	font-family: $gleego;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	height: 50px;

	&.btn-red {
		background-color: $red;
		color: $white;

		&:hover {
			background: #d43539;
		}
	}

	&.btn_add_to_cart {
		i {
			position: relative;
			top: -2px;
		}
	}

	&.wc-variation-is-unavailable {
		background: grey;
		pointer-events: none;
		cursor: text;

		&:hover {
			background: grey;
		}
	}
}