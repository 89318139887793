.woocommerce_author {
	.author_topinfo {
		padding-bottom: 36px;
		border-bottom: 1px solid #cdcdcb;
		margin-bottom: 28px;
	}

	p.short_description,
	p.long_description {
		text-align: center;
		max-width: 670px;
		margin-left: auto;
		margin-right: auto;
	}

	p.long_description {
		margin-bottom: 40px;
	}

	.author_avatar {
		display: block;
		border-radius: 100%;
		overflow: hidden;
		margin-top: 10px;
		margin-left: auto;
		margin-right: auto;
		width: 150px;
		height: 150px;

		img.avatar {
			display: block;
			margin-top: 0;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 16px;
		}
	}

	ul.author_buttons {
		text-align: center;
		padding: 0;
		list-style: none;
		max-width: 512px;
		margin-left: auto;
		margin-right: auto;
		font-size: 0;
		margin-bottom: -20px;

		li {
			display: inline-block;
			font-size: 16px;
			margin: 0 7px;
			margin-bottom: 20px;
		}
	}

	.author_sub {
		text-align: center;
	}

	.main_info {

		padding-top: 36px;
		border-top: 1px solid #cdcdcb;
		margin-top: 28px;
		> * {
			max-width: 670px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.fancyForm {
		button {
			width: 100%;
		}
	}

	.browse-authors {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;

		.fal {
			font-weight: bold;
		}

		a:first-child {
			.fal {
				margin-right: 10px;
			}
		}

		a:last-child {
			.fal {
				margin-left: 10px;
			}
		}

		&.first {
			justify-content: flex-end;
		}
	}

	.woocommerce-Price-amount {
		font-weight:normal;
	}
}

.author,.archive {
	.fancyForm {
		.gform_fields {
			li {
				margin-bottom: 10px;

				&.gfield.datepicker {
					&:after {
						right: 0;
					}
				}
			}
		}

		.gform_footer {
			button {
				width: 100%;
			}
		}
	}
}
