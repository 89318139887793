body.woocommerce-checkout {
    .site_footer {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.site_footer {
	color: $white;
	background: $black;
	padding-top: 50px;
	padding-bottom: 60px;
	text-align: center;

    

	.navbar-brand {
		display: block;
		max-width: 256px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
    }
    
    .newsletter-subscription {
        form {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 15px 0 30px 0;
            justify-content: center;

            .validation_error {
                width: 100%;
            }

            .gform_confirmation_message {
                margin-bottom: 20px;
                font-style: italic;
            }

            .gform_heading {
                width: 100%;
                display: inline-block;
                text-align: center;
                margin: 0 0 10px 0;

                h3.gform_title {
                    font-family: $gleego;
                    font-weight: 400;
                    text-transform: uppercase;
                    font-size: 20px;
                    color: $white;
                }
            }

            .gform_body {
                width: 100%;
                max-width: 320px;

                .gfield_label {
                    left: 18px !important;
                    top: 11px !important;
                }
            }

           .gform_footer {
                width: 100%;
                max-width: 140px;
                margin-left: 15px;

               button {
                width: 100%;
               }
           }

            input[type="submit"] {
                width: 100%;
            }

            input[type="email"] {
                width: 100%;
                max-width: 320px;

                @include media-breakpoint-down(sm) {
                    max-width: 240px;
                }

                @include input-placeholder {
                    font-style: italic;
                    opacity: 1;
                }
            }

        }
    }

	p {
		font-size: 15px;

		&.description {
			margin-bottom: 20px;
		}

		&.bottom {
			margin-bottom: 0;
			font-size: 0px;

			span {
				display: inline-block;
				font-size: 15px;
				&:after {
					content: "|";
					display: inline-block;
					margin: 0 10px;
				}
				&:last-child {
					&:after { display: none; }
				}
			}
		}

        .tbsfix {
            vertical-align: middle;
        }
	}
}

.footer_logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 400px;
    margin: 0 auto;
    align-items: center;

    a img.alignnone {
        margin: 0 5px;
    }
}