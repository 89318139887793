.component_show_form {
	background: $grey;
	color: $white;
	padding-top: 26px;
	padding-bottom: 15px;
	overflow: hidden;

	.component_header {
		color: #fff;
	}

	.gform_wrapper {
		
		.validation_error {
			text-align: center;
			flex: 0 0 100%;
			max-width: 100%;
		}

		form {
			display: flex;
			flex-wrap: wrap;
			margin-left: -20px;
			margin-right: -20px;

			@include media-breakpoint-down(md) {
				display: block;
			}
		}

		.gform_body {
			flex: 0 0 66%;
			max-width: 66%;
			padding: 0 20px;

			

			.gform_fields {
				display: flex;
				flex-wrap: wrap;
				margin-left: -20px;
				margin-right: -20px;

				li.gfield {
					flex: 0 0 50%;
					max-width: 50%;
					padding: 0 20px;

					&.gform_number, 
					&.gform_text,
					&.gform_textarea,
					&.gform_date,
					&.gform_phone,
					&.gform_website,
					&.gform_email {
						.gfield_label {
							left: 35px;
						}
					}

					&.gfield_error {
						input[type="text"],
						input[type="email"],
						input[type="date"],
						input[type="datetime-local"],
						input[type="number"],
						input[type="tel"],
						input[type="url"],
						input[type="search"],
						input[type="color"],
						input[type="month"],
						input[type="password"],
						input[type="tel"],
						input[type="week"],
						textarea {
							border-color: $red;
						}
					}
				}
			}

			@include media-breakpoint-down(md) {
				flex: none;
				max-width: 100%;

				.gform_fields {
					margin-left: -7px;
					margin-right: -7px;
					display: block;

					li.gfield {
						padding: 0 7px;
						max-width: 100%;
						margin-bottom: 15px;

						&.gform_number, 
						&.gform_text,
						&.gform_textarea,
						&.gform_date,
						&.gform_phone,
						&.gform_website,
						&.gform_email {
							.gfield_label {
								left: 20px;
							}
						}
					}
				}
			}
		}
		.gform_footer {
			flex: 0 0 33%;
			max-width: 33%;
			padding: 0 20px;

			.btn {
				width: 100%;
			}

			@include media-breakpoint-down(md) {
				flex: none;
				max-width: 100%;
				

				.btn {
					
				}
			}
		}
	}

	.footer_text {
		margin-bottom: 0;
		margin-top: 20px;
		text-align: center;
		font-size: 14px;
	}
}