// Form elements
input[type="text"],
input[type="email"],
input[type="date"],
input[type="datetime-local"],
input[type="number"],
input[type="tel"],
input[type="url"],
input[type="search"],
input[type="color"],
input[type="month"],
input[type="password"],
input[type="tel"],
input[type="week"]{
	border: 1px solid $grey;
	outline: none;
	width: 100%;
	height: 50px;
	background: $white;
	padding: 15px;
}
input[type="checkbox"]{

}
input[type="radio"]{

}
input[type="button"],
button {

}
textarea {

}
input[type="submit"] {

}
input[type="reset"] {

}
input[type="file"] {

}

.gform_confirmation_wrapper  {
	.gform_confirmation_message {
		text-align: center;
	}
}

// Email field fix for subscribe form
.component_show_form .gform_wrapper .gform_body .gform_fields li.gfield.gform_email {
    flex: 0 0 100%;	
    max-width: 100%;
}

.gform_wrapper {

	.validation_error {
		margin-bottom: 20px;
		font-style: italic;
	}

	.gform_heading {
		.gform_title {

		}
	}

	.gform_body {

	}

	.gform_footer {
		
	}

	ul.gform_fields {
		margin: 0;
		padding: 0;
		list-style: none;

		ul, li {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		li.gfield {
			position: relative;

			&.gform_hidden,
			&.gform_honeypot,
			&.artist_field {
				display: none;
			}

			&.gform_number, 
			&.gform_text,
			&.gform_textarea,
			&.gform_date,
			&.gform_phone,
			&.gform_website,
			&.gform_email {

				.gfield_label {
					position: absolute;
					top: 8px;
					left: 5px;
					z-index: 99;

					&.input_focus {
						font-size: 10px;
						margin-top: -7px;
					}
				}
			}

			&.datepicker {

				.hasDatepicker {
					padding-right: 70px;
				}

				&:after {
					content: "";
					width: 60px;
					height: 48px;
					background: $red;
					position: absolute;
					top: 1px;
					right: 20px;
					pointer-events: none;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					font-family: "Font Awesome 5 Pro";
					font-weight: 300;
					content: "\f073";
					text-align: center;
					line-height: 48px;
					font-size: 20px;
					color: #fff;

					@include media-breakpoint-down(md) {
						right: 0;
					}
				}

				.gfield_label {
					padding-right: 65px;
				}
			}

			.gfield_label {
				color: $grey;
				font-size: 16px;
				font-family: $opensans;
				font-style: italic;
				font-weight: 300;
				transition: all .2s;
				pointer-events: none;
				margin: 0;
			}

			.validation_message {
				position: absolute;
				bottom: -20px;
				font-size: 12px;
				color: $red;
			}
		}
	}
}

.ui-datepicker {
	font-size: 14px !important;
	z-index: 99995 !important;
}

.fancyForm {
	.gform_wrapper ul.gform_fields li.gfield.gform_text .gfield_label {
		left: 15px;
	}
}


.fancybox-container {
	textarea {
		max-width: 100%;
	}
}