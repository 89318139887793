.component_two_columns {
	padding-top: 40px;
	padding-bottom: 40px;

	.top_image {
		margin-bottom: 50px;

		img {
			display: block;
		}
	}

	.component_header {
		font-weight: normal;
		margin-bottom: 40px;
	}

	.row {
		position: relative;

		&:before {
			content: "";
			width: 1px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 50%;
			background: #cdcdcb;

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		.col-md-6 {

			>*:last-child {
				margin-bottom: 0;
			}
			
			&:first-child {
				>* {
					margin-right: 60px;

					@include media-breakpoint-down(sm) {
						margin-right: 0;

						&:last-child {
							margin-bottom: 15px;
						}
					}
				}
			}
			&:last-child {
				>* {
					margin-left: 60px;

					@include media-breakpoint-down(sm) {
						margin-left: 0;
					}
				}
			}

			
		}
	}

	.event_header {
		text-transform: uppercase;
		font-size: 16px;
		font-family: 'Open Sans';
		margin-bottom: 0;
	}

	p {
		&.date {
			font-style: italic;
			margin: 0;
		}
	}

	ul.posts_list {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-bottom: 15px;

		li {
			display: flex;
			justify-content: space-between;
		}

		a {
			color: #383736;

			&:hover {
				color: #f6595d;
			}
		}

		.year {
			color: #f6595d;
			font-weight: bold;
		}
	}

	.editor_content {
		margin-bottom: 15px;
	}
}