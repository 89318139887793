.component_logotypes_list {
	background: $white;
	padding: 100px 0;

	@include media-breakpoint-down(md) {
		padding: 40px 0;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;
		margin-bottom: -30px;

		li {
			display: inline-block;
			margin: 0 30px;
			margin-bottom: 30px;
		}
	}
}