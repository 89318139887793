.component_webshop_categories {
	padding-top: 60px;
	padding-bottom: 40px;

	@include media-breakpoint-down(sm) {
		padding-top: 15px;
		padding-bottom: 15px;

		.row {
			margin-bottom: -15px;

			> .col,
			> [class*="col-"] {
				margin-bottom: 15px;
			}
		}
	}

	img.webshop_category_image {
		display: block;
		margin-bottom: 25px;
	}

	p {
		font-size: 15px;
	}

	footer {
		font-size: 15px;
		font-family: $gleego;
		font-weight: 700;
		margin-bottom: 30px;
	}
}