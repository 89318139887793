.component_featured_artists {
	padding-top: 52px;
	padding-bottom: 60px;
	background: $white;

	@include media-breakpoint-down(sm) {
		padding: 15px 0;
	}

	.row {
		margin-bottom: -20px;

		.col {
			
		}
	}

	.description {
		text-align: center;
	}

	

	.w-100 {
		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	p.link {
		margin-bottom: 0;
		margin-top: 40px;
		text-align: center;
	}
}

.avatar_col {
	margin-bottom: 20px;
	padding-right: 0;
	padding-left: 0;

	a.avatar {
		display: block;
		border-radius: 100%;
		overflow: hidden;
		margin-bottom: 10px;
		margin-left: auto;
		margin-right: auto;
		width: 150px;
		height: 150px;

		img {
			display: block;
			margin: 0;
		}
	}

	&.avatar_col_big {
		margin-left: 20px;
		margin-right: 20px;
	}

	.author_info {
		text-align: center;
		font-size: 14px;
		margin-bottom: 0;

		a {
			font-family: $gleego;
			font-weight: 700;
			font-size: 16px;
		}

		.nr_products {
			display: inline-block;
		}
	}
}