.sub-component-more-content {
	.button {
		text-align: center;

		.btn {
			min-width: 325px;
			padding: 9px 15px;
			height: 42px;
		}
	}
}