
$megamenuLeftMenuWidth: 260px;
$megamenyLeftMiddleSpace: 20px;

$megamenuWidth-fullscreen: 1170px;
$megamenuMarginLeft-fullscreen: calc(50% - (#{$megamenuWidth-fullscreen} / 2));
$megamenu_Before_width-fullscreen: calc(50% - (#{$megamenuWidth-fullscreen} / 2) + #{$megamenuLeftMenuWidth} - 20px);
$megamenuRight_LeftStart-fullscreen: calc(50% - (#{$megamenuWidth-fullscreen} / 2) + #{$megamenuLeftMenuWidth});


$megamenuWidth-largescreen: 965px;
$megamenuMarginLeft-largescreen: calc(50% - (#{$megamenuWidth-largescreen} / 2));
$megamenu_Before_width-largescreen: calc(50% - (#{$megamenuWidth-largescreen} / 2) + #{$megamenuLeftMenuWidth} - 20px);
$megamenuRight_LeftStart-largescreen: calc(50% - (#{$megamenuWidth-largescreen} / 2) + #{$megamenuLeftMenuWidth});

$menuActiveAndHoverColor: $gold;


body.admin-bar {
	.site-header {
		top: 32px;
	}
}

.site-header {
	height: 139px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 31px;
	background: #fff;
	z-index: 200;
	box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.09);

	#toggle-mobile-menu {
		display: none;
	}

	.navbar-brand {
		width: 317px;
		height: 64px;
		display: block;
		position: absolute;
		top: 18px;
		left: 50%;
		transform: translateX(-50%);

		svg {
			width: 317px;
			height: 64px;
		}
	}

	.right {
		height: 55px;
		margin-top: 18px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	// hide images until menu is open to postpone lazy loading 
	ul.sub-menu {			
		img[data-lazy-src]:not(.lazyloaded) {
			display: none;
		}
		img.lazyloaded {
			display: block;
		}
	
		&.visible {
			>.imagewrap 
			{
				img {
					display: block;
				}
			}
			li.active {
				img {
					display: block;
				}
			}
		}
		
	}
	
	@media only screen and (min-width: 992px) {

		.navbar {
			text-align: center;
			margin-top: 30px;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			// level 1
			>ul {
				font-size: 0;
				
				// level 1
				>li {
					display: inline-block;
					font-size: 16px;
					line-height: 22px;
					padding: 0 25px;
					position: relative;

					>a {	
						color: #000;
						font-weight: 600;

						&:hover {
							color: #E56462;
						}

						i {
							margin-left: 5px;
							color: #E56462;
						}
					}

					&.active,
					&.current-menu-item,
					&.current-menu-ancestor {
						>a {
							color: #E56462;
						}
					}
					
					// level 2
					>ul {
						opacity: 0;
						pointer-events: none;
						transition: opacity 0.2s, transform 0.2s;
						position: absolute;
						top: 16px;
						left: 50%;
						background: #E56462;
						box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.09);
						transform: translate(-50%, 20px);
						width: 300px;
						padding: 26px 15px;

						&.visible {
							opacity: 1;
							pointer-events: all;
							
							>li.active {
								>a {
									color: $menuActiveAndHoverColor;
									font-weight: bold;
								}

								>.megamenu-right
								{
									opacity: 1;
									pointer-events: all;
									overflow: visible;
								}

							}
						}

						&:before {
							content: "";
							width: 0; 
							height: 0; 
							position: absolute;
							bottom: 100%;
							left: 50%;
							transform: translateX(-50%);							
						}
						
						// level 2
						li {
							margin-bottom: 16px;

							&:last-child {
								margin-bottom: 0;
							}

							.show-sub { display: none; }

							>a {
								color: #FFF;
								font-weight: 600;
								display: block;

								&:hover {
									color: $menuActiveAndHoverColor;
								}
							}
						}
					}				

					&.megamenu {
						position: static;
						
						// level 2
						>ul {
							
							&.visible
							{
								.megamenu-right.visible {								
										opacity: 1;
										pointer-events: all;
										overflow: visible;								
								}
							}

							position: fixed;
							top: 139px;
							bottom:0px;
							width: 100%;
							padding: 0;
							text-align: left;
							padding-left: 15px;
							padding-right:15px;
							padding-top:26px;
							left:0px;
							overflow: auto;
							overflow-y: overlay;
							transform: none;

							@media only screen and (max-width: 1200px) {
								//ZZ max-width: 950px;
							}
							
							>div.imagewrap
							{								
								overflow: hidden;    
								opacity: 1;
								top: 26px;
								padding: 0;
								position: absolute;
								left: $megamenuRight_LeftStart-fullscreen;
								
								@media only screen and (max-width: 1200px) {
									left: $megamenuRight_LeftStart-largescreen;
									max-width: 720px;
								}


								>.bookbtn
								{
									display: none;
								}

								>div.opacitylayer
								{
									position: absolute;
									top: 0px;
									background-color: #e56462d4;
									height: 100%;
									width: 100%;    
								}

							}


							&:before { 
								border: none;								
								width: $megamenu_Before_width-fullscreen;
								height: 100%;
								bottom: auto;
								top: 139px;
								left: 0px;								
								transform: none;
								background-color: #F2716F;
								z-index: 2;
								position: fixed;

								@media only screen and (max-width: 1200px) {
									width: $megamenu_Before_width-largescreen;
								}

							}

							// level 2
							>li {
								width: $megamenuLeftMenuWidth;								
								margin-bottom: 0;
								padding-bottom: 20px;
								max-height: calc(100vh - 139px);
								overflow: auto;
								margin-left: $megamenuMarginLeft-fullscreen;
								padding-right: calc(#{$megamenyLeftMiddleSpace} + 10px);

								@media only screen and (max-width: 1200px) {									
									margin-left: $megamenuMarginLeft-largescreen;
								}
							

								&:last-child {
									padding-bottom: 26px;
								}

								>a {
									position: relative;
									z-index: 3;
									display: block;
								}

								.show-sub { 
									display: inline-block; 
									transform: rotate(-90deg);
									position: relative;
									top: 2px;
									margin-left: 4px;
								}

								>.megamenu-right {
									opacity: 0;
									pointer-events: none;
									transition: opacity 0.2s;
									position: absolute;
									top: 0;									
									width: 925px;									
									padding-top: 26px;
									overflow: hidden;
									height: 100%;    								
									background-color: #e56462e3;									
									left: $megamenuRight_LeftStart-fullscreen;

									@media only screen and (max-width: 1200px) {
										width: 720px;
										left: $megamenuRight_LeftStart-largescreen;
									}

									>div.imagewrap
									{
										height: 100%;
										overflow: hidden;    
										position: absolute;
										top: 0px;
										padding-top: 26px;

										>div.opacitylayer
										{
											position: absolute;
											top: 0px;
											background-color: #e56462d4;
											height: 100%;
											width: 100%;    
										}

									}

									// level 3
									>ul {
										display: flex;
										flex-wrap: wrap;
										margin-left: 0;
										margin-top: 26px;
										align-items: stretch; /*To make them align att the top when one or more have a multi-line header*/
										position: absolute;
										align-content: start;
										width: 100%;

										
										// level 3
										>li {
											flex: 0 0 33%;
											max-width: 33%;
											padding: 0 10px;
											margin-bottom: 10px;
											display: block;
		
											>a {
												font-size: 16px;
												line-height: 1.2em;
												color:#fff;

												&:hover {
													color: $menuActiveAndHoverColor;
													span.author {
														color: $menuActiveAndHoverColor;
													}
												}
										

												span.author {
													font-size: 16px;
													text-align: center;
													color: #FFF;
													display: block;
													margin-bottom: 10px;

													img {
														margin: 0 auto;
														margin-bottom: 6px;
														border-radius: 100%;
													}

													i { display: none; }
												}
											}

											&.current-menu-item 
											{
												>a {
													color: $menuActiveAndHoverColor;
													font-weight: 700;
													span.author 
													{														
														color: $menuActiveAndHoverColor;
														font-weight: 700;
													}
												}
											}

											&.no-author-portrait {
												margin-bottom: 10px;
												text-align: center;

												>a {
													font-size: 16px;
													text-align: center;
													color: #FFF;
												}

												i { display: none; }
											}

											&.use_author_portrait {
												flex: none;
												min-width: 100%;
												
												// level 4 (menu-right panel structure)
												>ul
												{
													display: flex;
													flex-wrap: wrap;
													// level 4
													>li
													{
														flex: 0 0 33%;
														max-width: 33%;

														@media only screen and (min-width: 1200px) {
															flex: 0 0 25%;
															max-width: 25%;
														}
														

														>a {
															font-size: 16px;
															line-height: 1.2em;
															color:#fff;
			
															i {
																color: #e56462;
																margin-left: 5px;
															}

															&:hover {
																color: $menuActiveAndHoverColor;
																span.author {
																	color: $menuActiveAndHoverColor;
																}
															}
			
			
															span.author {
																font-size: 16px;
																text-align: center;
																color: #FFF;
																display: block;
																margin-bottom: 10px;
			
																img {
																	margin: 0 auto;
																	margin-bottom: 6px;
																	border-radius: 100%;
																}
			
																i { display: none; }
															}
														}

														&.active 
														{
															>a {
																color: $menuActiveAndHoverColor;
																font-weight: 700;
																span.author 
																{
																	color: $menuActiveAndHoverColor;
																	font-weight: 700;
																}
															}
														}
													}
												}
											}
										}
									}
								}

								&.use_author_portrait {
									.megamenu-right {
										// level 3
										> ul {
											&.more {
												margin-top: 0;
											}
											> li {												
												flex: 0 0 33%;
														max-width: 33%;

														@media only screen and (min-width: 1200px) {
															flex: 0 0 25%;
															max-width: 25%;
														}
											}
										}
									}
								}
							}
						}
						
					}
				}
			}
		}

	}

	.basket {
		position: relative;
		display: inline-block;
		font-size: 24px;
		color: #000;
		vertical-align: middle;
		
		&:hover {
			color: $red;
		}

		svg {
			width: 34px;
			height: 34px;
		}

		.cart-count {
			position: absolute;
			top: -2px;
			right: -12px;
			font-size: 12px;
			background: $red;
			color: $white;
			width: 16px;
			height: 16px;
			border-radius: 100%;
			text-align: center;
			line-height: 15px;
			font-weight: bold;
		}
	}

	.header-button {
		display: inline-block;
		padding: 12px 21px;
		background: #E56462;
		color: #fff;
		margin-right: 36px;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		//transition: background 0.2s;

		&:hover {
			background: #d43539;
		}
	}

	.search_form_container {
		position: relative;
		margin-right: 25px;
		display: inline-block;
		vertical-align: middle;

		.search_toggler {
			padding: 0;
			border: none;
			outline: none;
			background: none;
			cursor: pointer;
			font-size: 24px;
			color: #000;
			position: relative;
			z-index: 10;
			//transition: color 0.2s;
			margin-top: -3px;

			&:hover,
			&.active {
				color: $red;
			}
		}

		.search_form {
			position: absolute;
			top: -9px;
			right: -10px;
			width: 398px;
			z-index: 5;
			overflow: hidden;
			transition: width 0.2s;

			@media only screen and (max-width: 1360px) {
				width: 240px;
			}

			.search_field {
				border: 1px solid #CCC;
				height: 46px;
				padding: 0;
				padding-right: 30px;
				padding-left: 15px;
			}

			.search_submit {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				background: none;
				padding: 0 10px;
				outline: none;
				border: none;
				cursor: pointer;
			}
		}
	}

	@media only screen and (max-width: 991px) {
		height: 81px;
		padding: 0;
		
		.header-button {
			display: none;			
		}

		.navbar-brand {
			top: 16px;
			height: 42px;

			svg, img {
				height: 42px;
			}
		}

		.right {
			position: absolute;
			top: 20px;
			left: 16px;
			margin-top: 0;
		}
		#toggle-mobile-menu {
			position: absolute;
			top: 35px;
			right: 16px;
			margin-top: 0;
			display: block;
			cursor: pointer;
			height: 15px;

			span {
				display: block;
				height: 2px;
				background: $black;
				width: 35px;
				margin-top: 10px;
			}

			span:first-child {
				margin-top: 0;
			}

			&.mobile-menu-opened {
				height: 25px;

				span {
					transform: rotate(45deg);
					margin-top: 10px;
				}

				span:nth-of-type(2) {
					transform: rotate(-45deg);
					margin-top: -2px;
				}
			}
			
		}

		.navbar {
			display: none;
		}

		&.show-mobile-menu {
			height: 100vh;
	
			.navbar {
				position: absolute;
				top: 81px;
				left: 0;
				bottom: 0;
				width: 100%;
				display: block;
				background: $white;
				z-index: 120;
				float: none;
				padding: 0 15px;
				padding-top: 18px;
				padding-bottom: 60px;
				box-shadow: inset 0 4px 18px rgba(0,0,0,.09);
				overflow: auto;

				a {
					display: block;
					font-size: 18px;
					line-height: 25px;				
				}

				&.megamenu-sub-open {
					>ul>li>ul>.imagewrap
					{
						display: none;
					}
				}

				.search_form_container {
					display: block;
					margin-right: 0;
					margin-bottom: 18px;
		
					.search_form {
						position: static;
						width: auto;
						pointer-events: auto;
	
						input {
							opacity: 1;
							pointer-events: all;
							border-radius: 15px;
						}
					}
		
					button {
						position: absolute;
						top: 8px;
						right: 14px;
					}
				}
	
				ul {
					list-style: none;
					padding: 0;
					margin: 0;
					
				}

				// level 1
				>ul {
					// level 1
					li.use_author_portrait {
						// level 2
						>ul,
						.megamenu-right>ul {
							display: flex;
							flex-wrap: wrap;							
							padding-left: 15px;
							padding-right: 15px;
							padding-top: 15px;							
						
							// level 3
							>li {											
								flex: 0 0 33.333%;
								max-width: 33.333%;		
								&.menu-item-has-children 
								{
									flex: 0 0 100%;
									max-width: 100%;
									
								}	
								

								@media only screen and (max-width: 740px) {
									flex: 0 0 50%;
									max-width: 50%;
									padding: 3px;
									&.menu-item-has-children 
									{
										flex: 0 0 100%;
										max-width: 100%;
									}
								}
								text-align: center;
								margin-bottom: 15px;
								padding-top: 0;

								>a {
									margin-left: 0;
									margin-right: 0;
									color: #FFF;
									
									.author {

										.alignnone
										{
											margin:0px;
										}
										display: flex;
										flex-direction: column;
										justify-content: center;
										align-content: center;
										/* text-align: center; */
										flex-wrap: wrap;

										img {
											border-radius: 100%;
											display: block;
											margin: 0 auto;
											margin-bottom: 3px;
										}
										i { display: none; }
									}
								}

								&.no-author-portrait {
									i { display: none; }
								}
							}
						}
					}

					margin-left: -15px;
					margin-right: -15px;
					padding-bottom: 60px;

					// level 1
					>li {
						margin-bottom: 18px;

						&:last-child {
							margin-bottom: 0;							
						}

						.imagewrap {
							margin-top: 4px;
							margin-bottom: 10px;
							position: relative;
							display: flex;
							justify-content: flex-end;
							>.bookbtn
							{
								position: absolute;
								bottom: 10px;    
								right: 10px;
							}
						}

						>a {
							color: #000;							
							font-weight: 600;
							margin-left: 39px;
							margin-right: 15px;							

							.show-sub {
								color: #f6595d;
								display: inline-block;
								margin-left: 10px;
							}
						}

						&.active {
							>a {
								.show-sub {
									transform: rotate(180deg);
								}
							}
						}
						
						// level 2
						>ul { 
							background-color: #f2716f;
							margin-top: 16px;
							display: none;
							min-height: 0 !important;

							&.visible {
								opacity: 1;
								display: block;

								// Active visible when opening menu
								>li.active>.megamenu-right
								{
									opacity: 1;
									pointer-events: all;
									overflow: visible;
								}
							}
							
							// level 2
							>li {
								margin-bottom: 14px;

								&:last-child {
									padding-bottom: 22px;
									margin-bottom: 0;
								}

								&:first-child {
									padding-top: 22px;
								}

								>a {
									margin-left: 50px;
									margin-right: 15px;
									color: #FFF;

									.show-sub {
										margin-left: 10px;
										display: inline-block;
									}
								}

								&.active {
									>a {
										.show-sub {
											transform: rotate(180deg);
										}
									}
									background-color: #e56462e3;
								}

								// level 3
								>ul, &.active {
									
									>.megamenu-right {
										display: block;
									}
								}

								>.megamenu-right {
									display: none;
									background-color: #e56462e3;

									&.visible {
										display: block;
									}
								}

								// level 3
								>ul,
								.megamenu-right>ul {
									>li {
										margin-bottom: 5px;
										

										&:last-child {
											margin-bottom: 0;
											padding-bottom: 10px;
										}

										>a {
											margin-left: 75px;
											margin-right: 15px;
											//font-size: 16px;
											font-weight: 600;
											color: #FFF;
											//display: inline-block;

											i {
												margin-left: 6px;
												color: #f6595d;
											}
										}
									}
								}

							}
						 }
					}
				}
			}
	
			
		}
	}
}

body.woocommerce {
	.site-header {
		.navbar {
			ul {
				li#menu-item-32 {
					>a {
						color: $black;
					}
				}
			}
		}
	}
}
body.author {
	.site-header {
		.navbar {
			ul {
				li#menu-item-128 {
					>a {
						color: $black;
					}
				}
			}
		}
	}
}
