.woocommerce_archive,
.woocommerce_single {
	padding-top: 40px;
	padding-bottom: 40px;

	@include media-breakpoint-down(md) {
		//padding-top: 0;
	}

	.more-products {
		h2 {
			text-align: center;
			padding: 20px 0;
		}

		.link {
			text-align: center;
			padding: 20px 0;
		}
	}
}

.woocommerce_breadcrumbs {
	background: #e3e3e0;
	padding-top: 15px;

	@include media-breakpoint-down(md) {
		//padding-top: 50px;
	}

	select {
		width: 220px;
	}

	select, .select2 {
		margin-right: 20px;
		vertical-align: middle;
		margin-bottom: 15px;
	}

	span > a, .breadcrumbs_product, .breadcrumb_last {
		display: inline-block;
		background: #fff;
		min-height: 30px;
		line-height: 30px;
		vertical-align: middle;
		font-size: 16px;
		text-transform: uppercase;
		border-radius: 4px;
		padding: 0 8px;
		margin-bottom: 15px;
	}
}

.product_list {
	box-sizing: border-box;
	margin-left: -20px;
	margin-right: -20px;

	@include media-breakpoint-down(lg) {
		margin-left: -7px;
		margin-right: -7px;
	}

	&:after {
		content: "";
		clear: both;
		display: block;
	}

	.product {
		box-sizing: border-box;
		float: left;
		width: 25%;
		margin-bottom: 20px;
		padding-left: 20px;
		padding-right: 20px;
		width: 33.333%;

		@include media-breakpoint-down(lg) {
			width: 33.333%;
			padding-left: 7px;
			padding-right: 7px;
		}

		@include media-breakpoint-down(md) {
			width: 50%;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
		}

		.product_image {
			position: relative;
			margin-bottom: 10px;

			img {
				display: block;
				width: 100%;
			}

			.linkbtn {
				position: absolute;
				bottom: 2px;
				right: 2px;	
				opacity: 0;
				transition: width 2s;	
				
				@include media-breakpoint-down(md) {
					opacity: 1; /*Link button always visible on smaller devices*/
				}

				a {
					height: auto;				
    				padding: 10px 13px;
    				font-size: 16px;    
    				line-height: 16px;
				}
			}

			&:hover .linkbtn {
				opacity: 1;
				transition: opacity 1s ease-out;
			}
		}

		p {
			font-size: 14px;
			margin-bottom: 0;
			a {
				font-family: $gleego;
				font-weight: 700;
				font-size: 16px;
			}

			.price {
				display: block;
			}
		}
	}

	&.author_product_list {
		margin-left: -7px;
		margin-right: -7px;
		
		.product {
			width: 33.333%;
			padding-left: 7px;
			padding-right: 7px;

			@include media-breakpoint-down(md) {
				width: 50%;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
				float: none;
			}
		}
	}
}

.woocommerce-products-header__title {
	text-align: center;
}

.woocommerce-products-header {
	margin-bottom: 22px;
	.term-description {
		display: inline-block;
	}
}

.woocommerce_single {

	.woocommerce-product-gallery__wrapper {
		margin: 0;

		img {
			display: block;
			margin-bottom: 5px;
			margin-left: auto;
			margin-right: auto;
			width: auto;
			max-height: 600px;
		}

		p.image_links {
			margin: 0;
			text-align: center;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: bold;

			a {
				display: inline-block;
				margin: 0 5px;

				i {
					color: $black;
				}
			}
		}
	}

	.artist_info {
		.alignnone {
			margin: 0;
		}

		hr {
			margin: 26px 15px 0 15px;
			height: 1px;
			background: #cdcdcb;
			width: 100%;
			border: none;
		}

		.avatar {
			display: block;
			border-radius: 100%;
		}

		p {
			margin-bottom: 0;
		}
	}

	.variations {
		padding: 20px 0;
		margin-bottom: 27px;
		
		p {
			margin: 0;
			text-align: center;
		}

		.variations-header {
			font-weight: 700;
			margin-bottom: .5rem;
		}

		input[type=radio] {
			position: absolute;
			opacity: 0;

			&:checked + label {
				font-weight: 700;
			}

			&:checked + label:after {
				display: block;
			}
		}

		.value {
			position: relative;

			label {
				padding-left: 26px;
				cursor: pointer;
			}

			div {
				position: relative;

				label:before {
					position: absolute;
					cursor: pointer;
					top: 3px;
					left: 0;
					height: 20px;
					width: 20px;
					background: $white;
					border-radius: 50%;
					border: 1px solid $bars;
					content: "";
				}

				label:after {
					display: none;
					position: absolute;
					top: 6px;
					left: 3px;
					width: 14px;
					height: 14px;
					border-radius: 50%;
					background: $red;
					content: "";
				}
			}
		}
	}

	form.cart {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		#product-notice {
			font-family: Glegoo,serif;
			position: absolute;
			top: 20px;
			right: 0;

			span {
				position: absolute;
				top: 0;
				right: 0;
				border-radius: 50%;
				height: 25px;
				width: 25px;
				background: $bars;
				text-align: center;
				font-size: 14px;
				line-height: 25px;
				cursor: pointer;
				//transition: background 0.2s;

				&:hover {
					background: #bfbfba;
				}
			}
		}

		
	}

	.product_summary {
		.woocommerce-Price-amount {
				@include h2();

				display: block;
				width: 210px;
				margin-top: 27px;
			}

		.valueprops {
			font-weight : bold;
			font-family : Glegoo, serif;
			text-align:center;
			margin: 10px 0px;

			span:nth-of-type(odd) {
				color : rgb(246, 89, 93);
			  }

			} 
	}

	form.variations_form {
		display: block;

		.woocommerce-variation-add-to-cart {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	.single_variation_wrap {
		.woocommerce-Price-amount {
			margin-top: 0;
		}
	}

	.main_product_content {
		margin-top: 20px;
		border-top: 1px solid #cdcdcb;
		padding-top: 20px;
		margin-bottom: 34px;
	}

	.browse-products {
		position: relative;
		height: 50px;
		margin-bottom: 20px;

		a {
			display: block;
			position: absolute;
			right: 0;
			z-index: 10;
			width: 50px;

			&:first-child {
				left: 0;
			}
		}

		.fal {
			width: 50px;
			height: 50px;
			background: $bars;
			color: $red;
			text-align: center;
			font-size: 26px;
			font-weight: 500;
			line-height: 50px;
			cursor: pointer;
			vertical-align: top;
			//transition: background 0.2s;

			&:hover {
				background: #bfbfba;
			}

			&.fa-angle-left {
				border-right: 1px solid $background;
			}

			&.fa-angle-right {
				border-left: 1px solid $background;
			}
		}

		span {
			position: absolute;
			display: block;
			height: 50px;
			display: flex;
			align-items: center;
			background: $bars;
			padding: 0 60px;
			width: 100%;
			justify-content: center;
			z-index: 0;
			font-size: 14px;
			color: #787878;
			
		}
	}

	.testimonial {
		
		margin-bottom: 80px;

		.showcase {
			padding-right: 0;

			.showcase_image {
				height: 100%;
				background: $white;
				border: 1px solid $bars;
				border-right: none;
			}

			img {
				
				width: 100%;
			}
		}

		.quote {
			background: $white;
			font-family: Glegoo,serif;
			padding: 10% 5% 5% 10%;
			font-size: 18px;
			line-height: 32px;
			overflow: hidden;
			border-top: 1px solid $bars;
			border-bottom: 1px solid $bars;
			border-right: 1px solid $bars;

			&:before {
				position: absolute;
				margin: 32px 0 0 -55px;
				font-size: 100px;
				content: "\201C";
			}

			.signature {
				margin-top: 10px;
				font-family: 'Open Sans';
				font-size: 14px;
				font-weight: 300;
				font-style: italic;
				margin-bottom: 30px;

				&:before {
					content: "- ";
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.qty {
		appearance: none;
		-moz-appearance: textfield;
	}
}

.quantity {
	vertical-align: top;
	margin-bottom: 15px;
	flex: none;

	.qty_ctrl {
		display: inline-block;
		width: 40px;
		height: 50px;
		background: $bars;
		color: $red;
		text-align: center;
		font-size: 26px;
		line-height: 50px;
		cursor: pointer;
		vertical-align: top;
		//transition: background 0.2s;

		&:hover {
			background: #bfbfba;
		}
	}
	.qty {
		width: 130px;
		height: 50px;
		display: inline-block;
		border: none;
		outline: none;
		background: #fff;
		vertical-align: top;
		text-align: center;
	}
}

.woocommerce-checkout {
	#kco-wrapper {
		#kco-order-review {
			width: 100%;
			float: none;
			clear: both;
			padding-right: 0;

			#order_comments_field {
				label {
					@include h3();

					display: block;
					margin-top: 40px;
				}

				#order_comments {
					display: block;
					width: 100%;
					height: 100px;
				}
			}

			table {
				margin-bottom: 0;
			}
		}

		#kco-iframe {
			width: 100%;
			float: none;
			clear: both;
			padding-left: 0;
		}

		.finish-purchase {
			@include h2();

			background: $black;
			color: white;
			padding: 20px;
			text-align: center;
			margin-bottom: 0;
		}
	}
}

.woocommerce-checkout {

	table {
		width: 100%;
		font-size: 16px;
		margin-bottom: 60px;

		.product-total, .cart-subtotal td, .order-total td, .shipping td {
			text-align: right;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				label {
					margin: 0;
				}
			}
		}

		th {
			font-weight: 700;
		}

		tr {
			border-bottom: 1px solid $bars;
		}

		thead {
			tr {
				th {
					padding-bottom: 20px;
				}
			}
		}

		td {
			vertical-align: top;
			padding: 25px 0;

			span {
				display: inline-block;
				vertical-align: top;
			}
				
			&.product-name {
				img {
					display: block;
					float: left;

					@include media-breakpoint-down(xs) {
						float: none;
					}
				}

				span {
					display: block;
					float: left;
					font-weight: 700;
					color: $red;
					text-transform: uppercase;
					margin: 0 25px;

					@include media-breakpoint-down(md) {
						padding-bottom: 20px;
					}

					@include media-breakpoint-down(xs) {
						margin: 0;
						padding-top: 20px;
						float: none;
					}
				}

				input {
					display: block;
					float: left;
					margin: 0 25px;

					@include media-breakpoint-down(md) {
						float: none;
					}

					@include media-breakpoint-down(xs) {
						float: none;
						margin: 0;
					}
				}

				.remove_product {
					float: right;

					span {
						font-size: 30px;
						line-height: 24px;
						margin: 0 5px;
						font-weight: normal;
					}
				}
			}
		}

		.order-total, .cart-subtotal {
			border-bottom: 0;
		}
	}
}

.single-product {
	.woocommerce-message {
		display: none;
	}
}

#add_to_cart_popup {
	display: none;

	#cover {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $black;
		opacity: 0.65;
		z-index: 100;
	}

	a {
		margin: 0 5%;
		width: 40%;
		white-space: nowrap;
	}

	#popup {
		position: fixed;
		top: 50%;
		left: 50%;
		width: 500px;
		margin: -250px;
		margin-top: -120px;
		height: 240px;
		background: $background;
		text-align: center;
		padding: 35px;
		z-index: 100;

		h3 {
			line-height: 40px;
			font-weight: 400;
		}
	}
}

.widget_price_filter {
	.price_slider {
		margin: 30px .6em 0 .6em;
	}

	.ui-slider-range {
		background: $red;
	}

	.ui-slider-handle {
		background: $black;
		border-radius: 0;
		border-color: $red;
	}

	.ui-corner-all {
		border-radius: 0;
	}

	.price_slider_amount {
		margin-top: 15px;

		.button {
			float: right;
			border: none;
			padding: 5px 5px;
			font-size: 12px;
			//transition: background 0.2s, color 0.2s;
			text-align: center;
			font-family: $gleego;
			font-weight: 700;
			text-transform: uppercase;
			cursor: pointer;
			background-color: $red;
			color: $white;

			&:hover {
				background: #d43539;
			}
		}

		.price_label {
			float: left;
		}
	}
}

.woocommerce-pagination {
	.page-numbers {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		list-style: none;
		margin: 0 auto;
		padding: 0;

		li {
			background: $red;
			margin: 5px;
			
			a, .current, .dots {
				display: block;
				color: $white;
				padding: 5px;
				width: 40px;
				text-align: center;
			}
			.current {
				background-color:$black;
			}
		}
	}
}

.search_page {
	.woocommerce-search {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		list-style: none;
		margin: 0 auto;
		padding: 0;

		a, span {
			margin: 5px;
			display: block;
			padding: 5px 10px;
			text-align: center;
		}

		a, .dots {
			background: $red;
			color: $white;
		}

		.current {
			background: $black;
			color: $white;
		}
	}

	.woocommerce-search-header {
		margin-bottom: 30px;
	}

	.product-results {
		position: relative;
	}

	.artist-results {
		margin-bottom: 30px;
	}
}

#product-notice-content {
	width: 40%;
	display: none;

	@include media-breakpoint-down(md) {
		width: 70%
	}

	@include media-breakpoint-down(sm) {
		width: 95%
	}
}

@include media-breakpoint-down(xs) {
	#add_to_cart_popup {
		a {
			width: 100%;
			display: block;
			margin: 0 0 30px 0;
		}

		#popup {
			position: fixed;
			top: 30px;
			left: 30px;
			right: 30px;
			margin: 0;
			width: auto;
			height: 400px;
		}
	}

	.woocommerce_breadcrumbs {
		padding-bottom: 5px;

		select, .select2 {
			display: block;
			width: 100% !important;
		}

		span > a, .breadcrumbs_product, .breadcrumb_last {
			display: block;
		}
	}
}


.ywgc-preview,
.gift-card-content-editor.step-appearance { 
	display: none; 
}

.gift-cards-list {
	width: 100%;
}

.gift_card_add_to_cart_button {
	display: inline-block;
	border: none;
	padding: 12px 15px;
	font-size: 16px;
	//-webkit-transition: background .2s,color .2s;
	//transition: background .2s,color .2s;
	text-align: center;
	font-family: Glegoo,serif;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	height: 50px;
	background-color: #f6595d;
	color: #fff;
}

.print-info {
	display: none;
}


@media print {
	.site_footer, .site-header,
	#main, .component_footer_map,
	.site_footer {
		display: none !important;
	}

	body {
		background: #fff;
	}

	.print-info {
		display: block !important;

		* {
			display: block;
		}

		strong {
			display: inline;
		}
	}

	body { 
		text-align: center; 
	} 

	.print-logo {
		display: block; 
		width: 300px; 
		margin: auto;
	}
	.print-image { 
		position: relative; 
		overflow: hidden; 
		display: block;
		max-width: 500px;
		margin: 0 auto;
	}

	.print-watermark { 
		position: absolute; 
		top: 50%; 
		transform: translate(-50%, -50%); 
		left: 50%; 
		height: 30px; 
		display: block;
	}

	.print-product { 
		max-height: 700px; 
		width: auto; 
		max-width: 100%; 
		display: block;
	}

	.print-title {
		text-align: center; 
		margin-top: 50px;
	}

	strong {
		display: inline;
	}
}

.woocommerce-variation-add-to-cart-disabled {
	display: none !important;
}

.stock.out-of-stock {
	font-weight: bold;
}

#customer_details {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 60px;

	>div {
		flex: 0 0 50%;
		max-width: 50%;
		padding: 0 15px;
	}

	#ship-to-different-address {
		label {
			margin: 0;
		}
	}

	#order_comments {
		display: block;
		width: 100%;
		height: 148px;
	}

	.select2-container--default {
		.select2-selection--single {
			height: 50px;
			border-radius: 0;
			border-color: #383736;

			.select2-selection__rendered {
				line-height: 50px;
			}

			.select2-selection__arrow {
				border-left: none;
				height: 48px;
			}
		}
	}
}

body.woocommerce-checkout {
	.select2-dropdown {
		border-color: #383736;
		border-radius: 0;
	}
}

#order_review {
	.wc_payment_methods {
		list-style: none;
		margin: 0;
		padding: 0;
		margin-bottom: 20px;
	}
}

ul.woocommerce-error {
	color: $red;
}

#place_order {
	display: inline-block;
	border: none;
	padding: 12px 15px;
	font-size: 16px;
	//transition: background .2s,color .2s;
	text-align: center;
	font-family: Glegoo,serif;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	height: 50px;
	background-color: $red;
	color: #fff;

	&:hover {
		background: #d43539
	}
}