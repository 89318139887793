
.fancybox-button {
  opacity: 1;
  color:$white;
  background-color:$red;
  width:50px;
  height:50px;
  &:hover{
    opacity: 1;
    color:$white;
    background-color:$black;
    width:50px;
    height:50px;
  }
  &[disabled]{
    opacity:0.2;
    background-color:$black;
    width:50px;
    height:50px;
    &:hover{
      background-color:$black;
      width:50px;
      height:50px;
    }
  }
}


.fancybox-container {
    .fancybox-toolbar {
      opacity: 1;
    .fancybox-button--close {
      color:$white;
    }
  }
}

.fancybox-show-toolbar{
  .fancybox-toolbar{
    opacity: 1;
    visibility: visible;
    transition:none;
  }
}

.fancybox-button,
.fancybox-button:link,
.fancybox-button:visited {
  color:$white;
}

.fancybox-toolbar {
  visibility: visible;
}

.fancybox-slide {
  padding:100px;
  @media only screen and (max-width: 600px) {
    padding:10px;
  }
}


.author,.archive {
  .fancybox-toolbar {
    //visibility: hidden;
  }
}