.component_image_slider {
  margin: 40px 0;

  @include media-breakpoint-down(sm) {
    margin: 15px 0;
  }

  .glide {
    .glide__track {
      .glide__slides {
        .glide__slide {
          position: relative;
          font-size: 16px;
		  max-height: 590px;
		  height: inherit;

		  img {
			width: 100%;
		  }

		  @include media-breakpoint-down(xs) {
		  	padding-bottom: 170px;
		  }

          .image_slider_content {
			position: absolute;
			bottom: 0;
			width: 100%;			
			color: $white;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			padding-bottom: 28px;
			padding-top: 20px;
			padding-left: 200px;
			padding-right: 200px;
			z-index: 20;

			@include grey_bg_gradient();
	
			@include media-breakpoint-down(lg) {
			  padding-left: 100px;
			  padding-right: 100px;
			  padding-bottom: 38px;
			}
	
			@include media-breakpoint-down(xs) {
			  padding-left: 40px;
			  padding-right: 40px;
			  padding-bottom: 38px;
			  padding-top: 48px;
			}
	
			img {
			  display: block;
			}
	
			h2 {
			  color: $white;
	
			  @include media-breakpoint-down(sm) {
				font-size: 26px;
				line-height: 1.2;
			  }
			}
	
			p {
			  margin-bottom: 10px;
	
			  @include media-breakpoint-down(sm) {
				line-height: 1.2;
			  }
	
			  &:last-of-type {
				margin-bottom: 0;
			  }
	
			  &.link {
				text-transform: uppercase;
			  }
			}
		  }
        }
      }    
	}
	.glide__arrows {
		display: none;
	}
	@include media-breakpoint-up(lg) {
		.glide__arrows {
			display: none;
			user-select: none;
			position: absolute;
			top: 50%;
			width: 100%;
			justify-content: space-between;
			.glide__arrow {					
				background-color: #00000012;
    			color: $white;
				border-radius: 25px;
				font-size: 40px;
				width: 50px;
				height: 50px;
				border-style: none;
				padding: 0px;
				line-height: 10px;
				margin-right: 10px;
				margin-left: 10px;			
			}
		}
		&:hover .glide__arrows {
			display: flex;
		}
	}

	.glide__bullets {		
			display: flex;
			position: absolute;			
			text-align: center;
			padding: 0;
			margin: 0;			
			justify-content: center;
			bottom: 10px;			
			width: 100%;
			right: 0;
					
			.glide__bullet {
				margin: 0 10px;
				border: 0;
				background: 0 0;
				display: block;
				height: 20px;
				width: 20px;
				outline: 0;
				line-height: 0;
				font-size: 0;
				color: transparent;
				padding: 5px;
				cursor: pointer;
				
		
				.icon {
					width: 22px;
					height: 16px;
					background: url(../img/slick-dots.png);
					padding: 0;
		
					&:before { display: none; }
				}
		
				&.glide__bullet--active {
					.icon {
						background: url(../img/slick-dots-active.png);
					}
				}
			}
		

	}

  }

  .banners {
    [class*="col-"] {
      position: relative;
      height: 0;
      padding-bottom: 17.5%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      @include media-breakpoint-down(md) {
        padding-bottom: 25%;
      }

      @include media-breakpoint-down(sm) {
        padding-bottom: 35%;

        .banner_content h3 {
          font-size: 18px;
        }

        p {
          line-height: 1.2;
        }
      }

      @include media-breakpoint-down(xs) {
        padding-bottom: 50%;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        @include grey_bg_gradient();
      }

      .banner_content {
        position: absolute;
        bottom: 0;
        width: 100%;
        color: #fff;
        padding: 0 15px;
        z-index: 20;

        h3 {
          color: #fff;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}
