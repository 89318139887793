.component_content {
	padding-top: 40px;
	padding-bottom: 40px;

	@include media-breakpoint-down(md) {
		&:first-child {
			padding-top: 0;
		}
	}

	.sub-component {
		margin-bottom: 52px;

		@include media-breakpoint-down(md) {
			margin-bottom: 40px;
			
			&:last-child {
				margin-bottom: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:first-of-type {
			margin-top: 40px;
		}
	}
}