// Content from the WordPress editor
.editor_content {

	p {
		margin-bottom: 36px;
		a {}

		span.bigger {
			font-size: 20px;
		}
	}

	.embed-container {
		margin-bottom: 36px;
	}

	ul, ol {}

	blockquote, q {}

	>*:last-child {
		margin-bottom: 0;
	}

	&:after {
		content: "";
		clear: both;
		display: block;
	}

	h1, .h1 {
		margin-bottom: 22px;
	}

	h3, .h3 {
		margin-bottom: 28px;
	}

	img.icon {
		margin: 0;
		margin-top: 8px;
		margin-right: 12px;
		vertical-align: top;

	}

}

h1, .h1, p.h1  {
	@include h1;

	&.big {
		@include headerBig;
	}
}

h2, .h2, p.h2 {
	@include h2;

	&.big {
		@include headerBig;
	}
}

h3, .h3, p.h3 {
	@include h3;

	&.big {
		@include headerBig;
	}
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
