.sub-component-quotes-slider2 {
  background: $white;

  .editor_content {
    p {
      margin-bottom: 16px;
    }
  }

  .quote-inner {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;

    .editor_content {
      flex: 1;

      @include media-breakpoint-down(md) {
        order: 2;
      }      
    }

    figure {
      flex: 0 0 288px;
      max-width: 288px;
      margin-left: 30px;

      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
        margin-top: 20px;
        order: 1;

        img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
