// Clearfix
%clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
        width: 100%;
    }
}

// Input styles
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

// Fallback for svg
@mixin svg-background-fallback($image-url, $svg-url) {
  background: url($image-url);
  background: url($svg-url), linear-gradient(transparent, transparent);
}



@mixin h1() {
    font-family: $gleego;
    font-size: 40px;
    line-height: 54px;
    font-weight: 400;
    color: #444;
    margin-bottom: 5px;

    @include media-breakpoint-down(sm) {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 5px;
    }
}

@mixin h2() {
    font-family: $gleego;
    font-size: 30px;
    line-height: 27px;
    font-weight: 700;
    color: #444;
    margin-bottom: 24px;
}

@mixin h3() {
    font-family: $gleego;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    color: #444;
    margin-bottom: 8px;
    margin-top: 8px;
}

@mixin grey_bg_gradient {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#383736+0,383736+100&0+0,0.9+89 */
    background: -moz-linear-gradient(top, rgba(56,55,54,0) 0%, rgba(56,55,54,0.9) 89%, rgba(56,55,54,0.9) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(56,55,54,0) 0%,rgba(56,55,54,0.9) 89%,rgba(56,55,54,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(56,55,54,0) 0%,rgba(56,55,54,0.9) 89%,rgba(56,55,54,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00383736', endColorstr='#e6383736',GradientType=0 ); /* IE6-9 */
}

@mixin headerBig() {
    font-family: $gleego;
    font-size: 60px;
    line-height: 75px;
    margin-bottom: 25px;

    @include media-breakpoint-down(lg) {
        font-size: 40px;
        line-height: 54px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 30px;
        line-height: 40px;
    }
}