body {
	font-family: 'Open Sans', Arial, Helvetica, sans-serif;	
	font-size: 16px;
	color: #383736;
	background: $background;
	padding-top: 139px;

	@media only screen and (max-width: 991px) {
		padding-top: 81px;
	}

	&.mobile-menu-toggled {
		overflow: hidden;
		height: auto;
	}

	&.menu-open {
		overflow: hidden;
		height: auto;
		margin-right: 17px;

		.site-header {
			padding-right: 48px;
		}
	}
}

a {
	color: $red;
	//transition: color 0.2s;

	&:hover {
		color: #c93b3f;
		text-decoration: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.container-fluid {
	width: 100%;
	max-width: 1170px;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;

	> .col,
	> [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}

.component_header {
	text-align: center;
	margin-bottom: 20px;
}

.w-100 {
    width: 100% !important;
}

.not-found {
	padding-top: 40px;
	padding-bottom: 40px;
	text-align: center;
}

.tax_description {
	margin-top: 25px;
}

.sub-component-header {
	text-align: center;
	margin-bottom: 25px;
}