.component_featured_products {
	padding-top: 40px;
	padding-bottom: 83px;

	@include media-breakpoint-down(sm) {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.component_header {
		margin-bottom: 40px;
	}

	

	.link {
		margin-bottom: 0;
		margin-top: 30px;
		text-align: center;

		@include media-breakpoint-down(sm) {
			margin-top: 10px;
		}
	}
}

