.sub-component-quotes-slider {
  box-shadow: 10px 12px 29.58px 4.42px rgba(0, 0, 0, 0.1);

  > .h1 {
    text-align: center;
    background: $red;
    color: $white;
    padding: 43px 12px;
    margin: 0;

    @include media-breakpoint-down(md) {
      padding: 23px 12px;
    }
  }

  .glide {
    .glide__bullets {
      bottom: 35px;

      .glide__bullet {
        background: #f6595d;
        border-radius: 100%;
        opacity: 0.45;
        height: 12px;
        width: 12px;
        margin: 0 3px;
        &.glide__bullet--active {
          opacity: 0.16;
        }
      }
    }

    .glide__arrows {
      .glide__arrow {
        background-color: #ffffff;
        color: #f6595d;
      }
    }
  }

  .glide {
    .glide__track {
      .glide__slides {
        background: $white;
        padding-top: 34px;
        padding-bottom: 120px;

        @include media-breakpoint-down(md) {
          padding-left: 0px;
          padding-right: 50px;
          padding-top: 25px;
          padding-bottom: 70px;
        }

        .glide__slide {
          padding: 0 82px;
		  max-height: none;
          .quote-icon {
            display: block;
            color: $red;
            font-size: 30px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
